@tailwind base;
@tailwind components;
@tailwind utilities;

// fonts
@import "custom/fonts";

// general
@import "custom/general";

// colors
@import "custom/colors";

// components
@import "components/buttons";
@import "components/breadcrumb";
@import "components/menu";
@import "components/form";

// structure
@import "custom/structure/topnav";
@import "custom/structure/footer";

// pages
@import "custom/pages/helper";
@import "custom/pages/hero";
@import "custom/pages/countdown";
@import "custom/pages/portfolio";
@import "custom/pages/timeline";
@import "custom/pages/contact";

// plugins
@import "custom/plugins/swiper-slider";
@import "custom/plugins/testi";
@import "custom/plugins/choices";
@import "custom/plugins/datepicker";
@import "custom/plugins/toastification";
@import "custom/plugins/vue-select";
