// Color system
$white: #fff !default;
$gray-50: #f6f6f6 !default;
$gray-100: #babfc7 !default; // $gray-lightest
$gray-200: #ededed !default; // $gray-lighter
$gray-300: #dae1e7 !default; // $gray-light
$gray-400: #636363 !default;
$gray-500: #adb5bd !default;
$gray-600: #b8c2cc !default; // $gray
$gray-700: #4e5154 !default;
$gray-800: #1e1e1e !default; // $gray-dark
$gray-900: #2a2e30 !default;
$black: #22292f !default; // 231f48 22292f

$purple: #7367f0 !default; //$primary
$green: #28c76f !default; //$success
$blue: #00cfe8 !default; //$info
$orange: #f59e0b !default; //$warning
$red: #ea5455 !default; //$danger

$primary: $orange !default;
$secondary: #82868b !default;
$info: $blue !default;
$success: $green !default;
$warning: $orange !default;
$danger: $red !default;
$light: $gray-50 !default;
$dark: #4b4b4b !default;
