/* // _general.scss */

/*********************************/
/*             General            */
/*===============================*/
@import "colors";

p {
    @apply leading-relaxed;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    @apply leading-normal;
}

::selection {
    @apply bg-theme-orange/90 text-white;
}

*:focus {
  @apply outline-none;
}

@layer utilities {
  .divider-horizontal {
    @apply mx-0;
  }
}

@layer base {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

/* fade --------------------------------------------------------------------- */
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0
}


/* fade in up --------------------------------------------------------------- */
@keyframes fadeInUp {
  from {
    transform: translate3d(0, 40px, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
    opacity: 1
  }
}

.fade-in-up-leave-to {
  opacity: 0;
  transition: opacity .3s;
}

.fade-in-up-enter {
  opacity: 0;
  transform: translate3d(0, 40px, 0);
}

.fade-in-up-enter-to {
  opacity: 0;
  animation-duration: .7s;
  animation-fill-mode: both;
  animation-name: fadeInUp;
}

/* fade in down ------------------------------------------------------------- */
@keyframes fadeInDown {
  from {
    transform: translate3d(0, -40px, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
    opacity: 1
  }
}

.fade-in-down-leave-to {
  opacity: 0;
  transition: opacity .3s;
}

.fade-in-down-enter {
  opacity: 0;
  transform: translate3d(0, -40px, 0);
}

.fade-in-down-enter-to {
  opacity: 0;
  animation-duration: .7s;
  animation-fill-mode: both;
  animation-name: fadeInDown;
}

/* zoom fade ---------------------------------------------------------------- */
.zoom-fade-enter-active,
.zoom-fade-leave-active {
  transition: transform 0.35s, opacity 0.28s ease-in-out;
}
.zoom-fade-enter {
  transform: scale(0.97);
  opacity: 0;
}

.zoom-fade-leave-to {
  transform: scale(1.03);
  opacity: 0;
}

/* lazy load ---------------------------------------------------------------- */
.lazy-img-fadein[lazy=loaded] {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}
.lazy-img-fadein[lazy=loading] {
  // width: 40px!important;
  // margin: auto;
  // object-fit: contain !important;
  @apply object-cover object-center;
}
.lazy-img-fadein[lazy-progressive=true] {
  width: 100%!important;
  margin: auto;
}
.lazy-img-fadein[lazy=error] {
  border-radius: 2px;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}

/* shimmer ------------------------------------------------------------------ */
@keyframes background-shimmer{
  0%{
    background-position: 100% 0
  }
  100%{
    background-position: -100% 0
  }
}

.shimmer {
  @apply relative rounded-md;
  animation-duration: var(--shimmer-duration, 1.5s);
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: background-shimmer;
  animation-timing-function: var(--shimmer-timing-function, linear);
  background: #f3f3f3;
  background: linear-gradient(to right, #f3f3f3 8%, #ecebeb 18%, #f3f3f3 33%);
  background-size: 250%;
}

/* vue-horizontal ----------------------------------------------------------- */
.horizontal {
  cursor: move;
  user-select: none;
}

.horizontal {
  > .v-hl-btn-prev {
    left: 27px !important;
    top: 0;
    bottom: 0;
    transform: translateX(0);
  }
}

.horizontal {
  > .v-hl-btn-next {
    right: 27px !important;
    top: 0;
    bottom: 0;
    transform: translateX(0);
  }
}

.replaced-btn-prev {
  height: 100%;
  background: linear-gradient(to left, #ffffff00, white);
  padding-right: 48px;
  display: flex;
  align-items: center;
}

.replaced-btn-prev > div {
  font-weight: 700;
  font-size: 15px;
  line-height: 1;
  color: black;
  padding: 8px 12px;
  background: white;
  border-radius: 3px;
  border: 1px solid black;
}

.replaced-btn-next {
  height: 100%;
  background: linear-gradient(to right, #ffffff00, white);
  padding-left: 48px;
  display: flex;
  align-items: center;
}

.replaced-btn-next > div {
  font-weight: 700;
  font-size: 15px;
  line-height: 1;
  color: black;
  padding: 8px 12px;
  background: white;
  border-radius: 3px;
  border: 1px solid black;
}

/* floating vue ------------------------------------------------------------- */
.v-popper--theme-dropdown .v-popper__inner {
  @apply border-none rounded-md shadow-lg;
}

/* badge -------------------------------------------------------------------- */
.badge {
  @apply text-xs;
  &-theme {
    &-orange {
      @apply border-theme-orange bg-theme-orange;
    }
  }
}
