
.int-pm {
  @apply flex text-center justify-center items-center;
  .int-pm-value {
    @apply py-1 w-10;
  }
  .int-pm-btn {
    @apply cursor-pointer border border-[#040707]/10 p-1 rounded-md;
    &.int-pm-increment {
      @apply text-green-400 bg-green-400/10;
    }
    &.int-pm-decrement {
      @apply text-red-400 bg-red-400/10;
    }
    &.disabled {
      background-color: #eee;
      color: #777;
      cursor: not-allowed;
      &:hover {
        background-color: #eee;
      }
    }
  }
  &.int-pm-vertical {
    flex-direction: column;
    .int-pm-value {
      border-width: 0 1px;
      padding: 5px 10px;
    }
  }
}
