
// Normalize default styles across browsers,
// https://necolas.github.io/normalize.css/
@import '~normalize.css/normalize.css';

// Style loading bar between pages.
// https://github.com/rstacruz/nprogress
@import '~nprogress/nprogress.css';

#nprogress .bar {
  @apply bg-theme-orange;
}
#nprogress .spinner-icon {
  @apply border-t-theme-orange border-l-theme-orange;
}
