.profile-menu {
  :where(li:not(.menu-title):not(:empty)) > :where(*:not(ul):focus),
  :where(li:not(.menu-title):not(:empty)) > :where(*:not(ul):hover) {
    @apply text-theme-orange bg-theme-orange bg-opacity-10;
  }
  :where(li:not(.menu-title):not(:empty)) > :where(:not(ul).active),
  :where(li:not(.menu-title):not(:empty)) > :where(*:not(ul):active) {
    @apply text-theme-orange bg-theme-orange bg-opacity-10;
  }
  > li {
    &:hover,
    &.active,
    &.router-link-active {
      > a, span {
        @apply text-theme-orange #{!important};
      }
    }
    > a {
      &.disabled {
        @apply cursor-not-allowed pointer-events-none;
      }
    }
  }
}
