//  Active State
$vs-state-active-bg: rgba($orange, 0.12);
$vs-state-active-color: $orange !important;

@import 'vue-select/src/scss/vue-select.scss';

// Input style when dropdown is open
.vs--open .vs__dropdown-toggle {
  border-color: $orange;
  border-bottom-color: $orange;
}

// Dropdown Position
.vs__dropdown-menu {
  border-color: $orange;
}

.form-select {

  &.vs--single.vs--open .vs__selected,
  &.vs--single .vs__selected {
    margin-top: 0;
  }

  .vs__dropdown-toggle {
    padding: 8px;

    input {
      margin-top: 0;
    }
  }

  &.is-invalid .vs__dropdown-toggle {
    @apply border-error;
  }
}
