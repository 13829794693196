/*********************************/
/*             Helper            */
/*===============================*/
.container {
  @apply relative;
}

/* Cookies */
.cookie-popup-not-accepted {
  @apply block;
  animation: cookie-popup-in 0.5s ease forwards;
}

.cookie-popup-accepted {
  @apply hidden;
}

@keyframes cookie-popup-in {
  from {
    bottom: -6.25rem;
  }

  to {
    bottom: 1.25rem;
  }
}

/* Shapes */
.shape {
  @apply absolute right-0 -bottom-px left-0;
  & > svg {
    @apply w-full h-auto;
    transform: scale(2);
    transform-origin: theme("transformOrigin.top")
      theme("transformOrigin.center");
  }

  &.marketing-hero {
    clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
    @apply h-[250px] bg-white;
  }
}
@media (max-width: 768px) {
  .shape {
    &.marketing-hero {
      @apply h-[140px];
    }
  }
}

@media (max-width: 425px) {
  .shape {
    @apply -bottom-[2px];
    &.marketing-hero {
      @apply h-[60px];
    }
  }
}

/* Feature post placeholder */
.feature-posts-placeholder {
  @apply absolute bottom-0 left-0 right-0 h-2/3;
}

@media (max-width: 425px) {
  .feature-posts-placeholder {
    @apply h-[80%];
  }
}

.features-absolute {
  @apply relative z-2 transition-all duration-500 ease-in-out -mt-[200px] m-0;
  &.blog-search {
    @apply -mt-[120px] m-0;
  }
}

@media (max-width: 768px) {
  .features-absolute {
    @apply -mt-[140px] m-0;
    &.blog-search {
      @apply -mt-[90px] m-0;
    }
  }
}

/* Preloader */
#preloader {
  background-image: linear-gradient(45deg, #ffffff, #ffffff);
  z-index: 99999;
  @apply absolute inset-0;
  #status {
    @apply absolute left-0 right-0 top-1/2 -translate-y-1/2;
    .spinner {
      @apply w-10 h-10 relative mx-auto;
      .double-bounce1,
      .double-bounce2 {
        @apply w-full h-full rounded-full bg-theme-orange/60 absolute top-0 left-0;
        animation: sk-bounce 2s infinite ease-in-out;
      }
      .double-bounce2 {
        animation-delay: -1s;
      }
    }
  }
}

#loader {
  @apply absolute left-0 right-0 top-1/2 -translate-y-1/2;
  .spinner {
    @apply w-10 h-10 relative mx-auto;
    .double-bounce1,
    .double-bounce2 {
      @apply w-full h-full rounded-full bg-theme-orange/60 absolute top-0 left-0;
      animation: sk-bounce 2s infinite ease-in-out;
    }
    .double-bounce2 {
      animation-delay: -1s;
    }
  }
}

@keyframes sk-bounce {
  0%,
  100% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
}

/* Switcher */
.label {
  .ball {
    transition: transform 0.2s linear;
    @apply translate-x-0;
  }
}

.checkbox:checked + .label .ball {
  @apply translate-x-6;
}

//Mover
.mover {
  animation: mover 1.5s infinite alternate;
}
@keyframes mover {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(10px);
  }
}
