/*********************************/
/*         Menu                  */
/*===============================*/
.menu-extras {
  @apply float-right;
  .menu-item {
    @apply border-gray-200;
  }
}

.navbar-toggle {
  @apply border-0 relative p-0 m-0 cursor-pointer;
  .lines {
    @apply w-[25px] block relative h-[18px] mr-0 ml-[10px];
  }
  span {
    transition: transform 0.5s ease;
    @apply h-[2px] w-full bg-black block mb-[5px];
    &:last-child {
      @apply mb-0;
    }
  }
  &.open {
    span {
      @apply absolute;
      &:first-child {
        @apply top-[6px] rotate-[45deg];
      }
      &:nth-child(2) {
        @apply invisible;
      }
      &:last-child {
        @apply w-full top-[6px] -rotate-[45deg];
      }
      &:hover {
        @apply bg-theme-orange;
      }
    }
  }
  &:hover,
  &:focus {
    @apply bg-transparent;
  }
}

.navigation-menu {
  :where(li:not(.menu-title):not(:empty)) > :where(*:not(ul):focus),
  :where(li:not(.menu-title):not(:empty)) > :where(*:not(ul):hover) {
    @apply bg-transparent;
  }
  :where(li:not(.menu-title):not(:empty)) > :where(:not(ul).active),
  :where(li:not(.menu-title):not(:empty)) > :where(*:not(ul):active) {
    @apply bg-transparent;
  }
  > li {
    &:hover,
    &.active,
    &.router-link-active {
      > a {
        @apply text-theme-orange #{!important};
      }
    }
  }
}

@media (min-width: 768px) {
  .navbar-toggle {
    @apply block;
  }
}

@media (min-width: 992px) {
  #navigation {
    @apply block #{!important};
  }
  .navbar-toggle {
    @apply hidden;
  }
  .navigation-menu {
    @apply inline-flex flex-row;
    :where(li) {
      @apply flex-row;
    }
  }
}

@media (max-width: 991px) {
  #navigation {
    @apply absolute top-[63px] left-0 w-full hidden h-auto bg-white overflow-auto z-10;
    &.open {
      @apply block overflow-y-auto;
    }
  }
  .navigation-menu {
    :where(li) {
      @apply items-end;
    }
  }
}
