/********************/
/*    Breadcrumb    */
/*==================*/

.breadcrumb {
  .breadcrumb-item {
    @apply before:content-[''] after:text-sm after:text-black after:pl-[5px] last:after:hidden;
    &:after {
      content: "\F0142" !important;
      font-family: "Material Design Icons";
    }
  }
  &.breadcrumb-light {
    .breadcrumb-item {
      @apply after:text-white/50;
    }
  }
}
